import React from "react";
import { Helmet } from 'react-helmet';
import { Link, graphql } from "gatsby";
import * as BS from "react-bootstrap";
import 'bootstrap/dist/css/bootstrap.min.css';
import { BreadcrumbLite } from '../../components/common/BreadcrumbLite';
import styled from 'styled-components';
import Markets from '../../components/featured/markets';

const RightCol = styled.div`
  text-align: right;
  @media only screen and (max-width: 768px) {
    text-align: left;
  }
`

const Healthtech = (props) => {


  const renderInvestor = (edge, index) => {

  
    return (
      <section>
        <BS.Card key={index} style={{ marginTop: 5, marginBottom: 5 }}>
          <BS.Card.Body>
            <BS.Row>
              <BS.Col sm={6}>
                < Link to={`/investor/${edge.node.name.toLowerCase().replace(/ /g, "-")}/`}>{edge.node.name}</Link>
              </BS.Col>
              <BS.Col sm={6}><RightCol>
                investments: <b>{edge.node.healthtech}</b>
              </RightCol>
              </BS.Col>
            </BS.Row>
          </BS.Card.Body>
        </BS.Card>
      </section>
    )
  }




  const renderPlayer = (edge, index) => {

    const isLogo = () => {
      if (edge.node.imageUrl && edge.node.localImage.publicURL !== '/static/ba1252a8ae734065b3e3ef7ed2320180/1.png') {
        return (
          <BS.Card.Img style={{marginBottom: 0, maxWidth: 100}} variant="top" src={edge.node.localImage.publicURL} />
        )
      } 
    }

  //  const isLogo2 = () => {


  

  //   //     if(pic.node.base === edge.node.imageFile.split('/')[1]){
  //   //       console.log('found it')
  //         if (1 === 1){
  //           return (
        
  //         <h3>hello</h3>
         
       
  //       )

  //     }
  //       }
  
      // })

    



    return (
      <section>
        <BS.Card key={index} style={{ marginTop: 5, marginBottom: 5 }}>
          <BS.Card.Body>

            <BS.Row>
              <BS.Col sm={6}>
                < Link to={`/business/${edge.node.name.toLowerCase().replace(/ /g, "-")}/`}>{edge.node.name} {isLogo()} </Link>
              </BS.Col>
              <BS.Col sm={6}><RightCol>
                funds raised: <b>{edge.node.currency}{new Intl.NumberFormat().format(edge.node.totalRaised)}</b>
              </RightCol>
              </BS.Col>
            </BS.Row>
          </BS.Card.Body>
        </BS.Card>
      </section>
    )
  }

  return (

    <section>
      <Helmet>
        <html lang="en" />
        <title>Healthtech Market By Unicorn Alert</title>
        <meta name="title" content="Healthtech Market By Unicorn Alert"></meta>
        <meta name="description" content='Get the latest updates from Healthtech startups, investors and fundraising in your realtime Unicorn Alert dashboard.' />
      </Helmet>
      <BS.Container style={{ paddingLeft: 0, paddingRight: 0, maxWidth: 1400 }}>
      <BreadcrumbLite>
        <BS.Breadcrumb.Item> <Link to={`/`}>Home</Link></BS.Breadcrumb.Item>
        <BS.Breadcrumb.Item> <Link to={`/market/`}>Markets</Link></BS.Breadcrumb.Item>
        <BS.Breadcrumb.Item active>Healthtech</BS.Breadcrumb.Item>
      </BreadcrumbLite>
      <BS.Row style={{display: 'block'}}>
              <BS.Col sm={12}>
              <h1>Healthtech - private equity and venture capital deals</h1>
                <h2 style={{color: '#34383b', fontWeight: 300, marginBottom: 25}}>Businesses and investors ranked by investments</h2>
                </BS.Col>
        </BS.Row>
            <BS.Row>

            <BS.Col sm={4}>
            <BS.Card style={{ marginTop: 20 }}>
              <BS.Card.Header>Businesses</BS.Card.Header>
            </BS.Card>
            {props.data.allBusiness.edges.map(renderPlayer)}

         
          </BS.Col>
          <BS.Col sm={4}>
            <BS.Card style={{ marginTop: 20 }}>
              <BS.Card.Header>Investors</BS.Card.Header>
            </BS.Card>
            {props.data.allInvestor.edges.map(renderInvestor)}

            

           
          </BS.Col>

          <BS.Col sm={4} style={{ marginTop: 20 }}>
            <Markets />
          </BS.Col>
        </BS.Row>

      </BS.Container>

    </section >

  );
}

export const query = graphql`
{
  allBusiness(sort: {order: DESC, fields: totalRaised}, filter: {what: {eq: "healthtech"}}) {
    edges {
      node {
        name
        id
        currency
        totalRaised
        what
        imageFile

    
      }
    }
  }
  allInvestor(filter: {healthtech: {gt: 0}}, sort: {fields: healthtech, order: DESC}) {
    edges {
      node {
        name
        healthtech
      }
    }
  }

  allFile(filter: {relativeDirectory: {eq: "business-logos"}}) {
    edges {
      node {
        base
        childImageSharp {
          fluid(maxWidth: 300) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
    }
  } 
}
`

export default Healthtech
